import React, { Component} from 'react';
import axios from 'axios';
import url from 'url';
import '../App.css';
 

 
class MyChatBotForm extends Component {
  
  constructor(props) {
        super(props);
       // const apiUrl = 'https://j1nbknreme.execute-api.us-west-2.amazonaws.com/DP-fctest/{myexpproxy+}';  
      }

    state = {
         menuItemSelected : "",
         file: null,
         selectedFileName: "",
         selectedZipFileName:""
      };
      
      //Start: upload video
     // const [file, setFile] = useState(null);

      handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        //setFile(selectedFile);
        this.setState({file: selectedFile});

        // Update the selected file name for display
        this.setState({selectedFileName: selectedFile.name});
      };
      //End: upload video

      //Start: upload voice recorded zip file
     handleZipFileChange = (e) => {
      const selectedZipFile = e.target.files[0];
      //setFile(selectedFile);
      this.setState({file: selectedZipFile});

      // Update the selected file name for display
      this.setState({selectedZipFileName: selectedZipFile.name});
    };
    //End: upload video

  

      //refer to https://reactjs.org/docs/forms.html#controlled-components
  handleClick = async (token, email, action, voiceprjuuid, voiceuuid) => {
    //console.log("in chatbotform.jsx, submit button clicked, handleClick() start....token is:", token);
    let presignedUrl = "";
    const data = { 
      "email": email
    };
    const header = {
      headers: {
          'Authorization': `${token}`
        // 'Authorization': `${token}` + 'ftest-bad-token-tail'  //this is for testing cognito authorizer, it works (ie. blocked or get error) if we add any extra chars in the true id_token.
        // 'Authorization': `Basic ${token}` 
      }
    };
    //console.log("chatbotform.jsx, in handleClick(), header is:", header);

    //TBD: Start uploading user video

    if (action.indexOf("video")>-1) { //for uploading user video
      //console.log("in chatbotform.jsx, menuItemSelected is upload your video");
      if (this.state.file) {
        //console.log("should be video/mp4, state.file.type=",this.state.file.type);
        if(this.state.file.type.includes('video')) { //04/27/24
          const data6 = { 
            "email": email,
            "actionFlag": "getPresignedURL4UploadingVideo"
          };
  
          const apiUrl6 = 'https://hf4gwgts95.execute-api.us-west-2.amazonaws.com/prod/fineTuneGPTModel'; 
        
          axios.post(apiUrl6, data6, header)
          .then((response) => {
              //console.log("*****content-type:", this.state.file.type);
              //console.log("chatbotform.jsx, handleClick() for uploading video, get s3 presigned url response:", response);
              presignedUrl = response.data;
              //console.log("presignedURL:", presignedUrl);
              //alert("get s3 presignedUrl successfully:"); 
          }).then(()=>{
            // Use the presigned URL to upload the file directly to S3
            //console.log("*****content-type:", this.state.file.type);
            const options = {
              headers: {
                'Content-Type': this.state.file.type, // Set the content type of the file
              },
            };
            axios.put(presignedUrl, this.state.file, options);
          }).then(()=>{
            alert('Video File uploaded successfully'); //to S3
          }
          ).catch((error) => {
              console.log("chatbotform.jsx, handleClick() for upload video file, get error:", error);
          });

        } 
        // 04/27/24 for allowing user to upload image, instead of video
        else if(this.state.file.type.includes('image')) { //04/27/24
          //05/01/24
          const data68 = { 
            "email": email,
            "actionFlag": "getPresignedURL4UploadingImage",
            "imageFileExtension": this.state.file.type.split("/")[1] 
          };
  
          const apiUrl68 = 'https://hf4gwgts95.execute-api.us-west-2.amazonaws.com/prod/fineTuneGPTModel'; 
        
          axios.post(apiUrl68, data68, header)
          .then((response) => {
              //console.log("*****content-type:", this.state.file.type);
             // console.log("chatbotform.jsx, handleClick() for uploading image, get s3 presigned url response:", response);
              presignedUrl = response.data;
              //console.log("presignedURL:", presignedUrl);
              //alert("get s3 presignedUrl successfully:"); 
          }).then(()=>{
            // Use the presigned URL to upload the file directly to S3
            //console.log("*****upload image, content-type:", this.state.file.type);
            const options = {
              headers: {
                'Content-Type': this.state.file.type, // Set the content type of the file
              },
            };
            axios.put(presignedUrl, this.state.file, options);
          }).then(()=>{
            alert('Image File uploadeded successfully'); //to S3
          }
          ).catch((error) => {
              console.log("chatbotform.jsx, handleClick() for upload image file, get error:", error);
          });

          /* 05/01/25 replace this block w the above to use presigned url to upload
          console.log("should be image/jpg, this.state.file.type=", this.state.file.type);
            const formData = new FormData();
            formData.append('file', this.state.file);
            formData.append("email", email,);
            formData.append("actionFlag", "uploadImage",);
    
            const uploadFileheader = {
              headers: {
                  'Authorization': `${token}`,
                  'Content-Type': 'multipart/form-data'
              }
            };
    
            const apiUrl68 = 'https://hf4gwgts95.execute-api.us-west-2.amazonaws.com/prod/fineTuneGPTModel'; 
          
            //axios.post(apiUrl68, formData, header)
            axios.post(apiUrl68, formData, uploadFileheader)
            .then((response) => {
                console.log("chatbotform.jsx, handleClick() for uploading image, get response:", response);
                alert("Your picture is uploaded successfully."); 
            }).catch((error) => {
                console.log("chatbotform.jsx, handleClick() for upload image, get error:", error);
            });*/
        } 
        else {
          alert("error: wrong files.");
        }
        
      }
     /*  comment out this block because APIG has 6MB max size limitation and video size > 6MB
         hence, use the above block to do it via presignedURL to upload to S3 directly.
      if (this.state.file) {
        const formData = new FormData();
        formData.append('file', this.state.file);
        formData.append("email", email,);
        formData.append("actionFlag", "uploadVideo",);

        const uploadFileheader = {
          headers: {
              'Authorization': `${token}`,
              'Content-Type': 'multipart/form-data'
          }
        };

        const apiUrl6 = 'https://hf4gwgts95.execute-api.us-west-2.amazonaws.com/prod/fineTuneGPTModel'; 
      
        axios.post(apiUrl6, formData, header)
        .then((response) => {
            console.log("chatbotform.jsx, handleClick() for uploading video, get response:", response);
            alert("Your video is uploaded successfully."); 
        }).catch((error) => {
            console.log("chatbotform.jsx, handleClick() for upload video, get error:", error);
        });
      }*/
    }
    //End uploading user video
    
    else if (action.indexOf("data")>-1) { 

     // console.log("in chatbotform.jsx, menuItemSelected is to create your model training txt data for langchain FW.");
        const apiUrl = ' https://c0o8gnzty3.execute-api.us-west-2.amazonaws.com/prod/createTXT4LangChainModel'; 
        axios.post(apiUrl, data, header)
        .then((response) => {
            //console.log("chatbotform.jsx, handleClick() for creating  txt model training data, get response:", response);
            alert("Your legacy is created successfully."); 
        }).catch((error) => {
            console.log("chatbotform.jsx, handleClick() for creating  model training txt data, get error:", error);
        });
      
      /* this block code is replaced by the above, which is to use langChain framework w/ OpenAI to create chatbot. 
      //this block code is to prepare the csv training data for Open AI's Finetune data training model/approach.
      //for creating training CSV data file for training fine-tuned chat model via API from openai.com
      console.log("in chatbotform.jsx, menuItemSelected is create your model training data csv");
        const apiUrl = ' https://c0o8gnzty3.execute-api.us-west-2.amazonaws.com/prod/createCSV4GPTModel'; 
        axios.post(apiUrl, data, header)
        .then((response) => {
            console.log("chatbotform.jsx, handleClick() for creating model training data, get response:", response);
            alert("Your model training data is created successfully."); 
        }).catch((error) => {
            console.log("chatbotform.jsx, handleClick() for creating model training data, get error:", error);
        });*/
    } else if (action.indexOf("status")>-1) { //for checking chatbot model status (ready for using or not)
      //***120123: this block/condition will not need any more since it is only for using openai finetune model creation status checking.
      //console.log("in chatbotform.jsx, menuItemSelected is check your chatbot model status");
      const data1 = { 
        "email": email,
        "actionFlag": "get"
      };
      //***For somereason, axios.get (ie. httpMethod is Get) cause the CORS issue
      //***hence twist to use asios.post to handle the get profile/bot record data based on the actionFlag in data1
      const apiUrl1 = 'https://hf4gwgts95.execute-api.us-west-2.amazonaws.com/prod/fineTuneGPTModel'; 
        axios.post(apiUrl1, data1, header)
        .then((response) => { 
            
            //console.log("chatbotform.jsx, handleClick() for checking chatbot model status, get response:", JSON.stringify(response));
            //somehow, the response schema is diff from the API/Lambda's returned response in fineTuneGPTModelPythonLambda
            //using the console.log to see the response here, find the api's response's body value is in the received reponse's data.
            //the reason is likely that AWS APIG has somewhat Lambda API response transformation. Hence, the resp format is changed.
            alert("Your chatbot model status is: " + response.data);  //alert use "+" to combines strings.
            
        }).catch((error) => {
            alert("get error");
            console.log("chatbotform.jsx, handleClick() for checking chatbot model status, get error:", error);
        });

    } else if (action.indexOf("voice uuids")>-1) { //for setting cloned voice which users get from resemble.ai
      //console.log("in chatbotform.jsx, menuItemSelected: set your voice uuids");
      const data5 = { 
        "email": email,
        "actionFlag": "updateVoiceUUID",
        "vpuuid": voiceprjuuid, //document.getElementById("voiceProjectUUID").value,
        "vuuid": voiceuuid,//document.getElementById("voiceUUID").value,
      };
      const apiUrl5 = 'https://hf4gwgts95.execute-api.us-west-2.amazonaws.com/prod/fineTuneGPTModel'; 
        axios.post(apiUrl5, data5, header)
        .then((response) => {
            
            //console.log("chatbotform.jsx, handleClick() for checking chatbot model status, get response:", JSON.stringify(response));
            //somehow, the response schema is diff from the API/Lambda's returned response in fineTuneGPTModelPythonLambda
            //using the console.log to see the response here, find the api's response's body value is in the received reponse's data.
            //the reason is likely that AWS APIG has somewhat Lambda API response transformation. Hence, the resp format is changed.
            // r = response.data;
            alert("OK: " + response.data.replace("UUIDs", ""));  //alert use "+" to combines strings.
            
        }).catch((error) => {
            alert("get error");
            console.log("chatbotform.jsx, handleClick() for setting voice uuids, get error:", error);
        });

    } 
    //start uploading voice zip file
    else if (action.indexOf("clone")>-1) { //for uploading user voice zip file
      //console.log("in chatbotform.jsx, menuItemSelected is clone your voice");
      if (this.state.file) {
        const data7 = { 
          "email": email,
          "actionFlag": "getPresignedURL4UploadingVoiceZipFile"
        };

        const apiUrl7 = 'https://hf4gwgts95.execute-api.us-west-2.amazonaws.com/prod/fineTuneGPTModel'; 
      
        axios.post(apiUrl7, data7, header)
        .then((response) => {
            //console.log("*****content-type:", this.state.file.type);
          //  console.log("chatbotform.jsx, handleClick() for uploading voice zip file, get s3 presigned url response:", response);
            presignedUrl = response.data;
           // console.log("presignedURL:", presignedUrl);
           // alert("get s3 presignedUrl for uploading voice zip file successfully:"); 
        }).then(()=>{
          // Use the presigned URL to upload the file directly to S3
          //console.log("*****content-type:", this.state.file.type);
          const options = {
            headers: {
              'Content-Type': this.state.file.type, // Set the content type of the file
            },
          };
          axios.put(presignedUrl, this.state.file, options);
        }).then(()=>{
          alert('Voice Zip File uploaded successfully'); //to S3
        }
        ).catch((error) => {
            console.log("chatbotform.jsx, handleClick() for upload voice zip file, get error:", error);
        });
      }
    }
      //end upload voice zip file
    else { //for creating chatbot model based on the created training CSV data done in the prev step. refer to code line#34
      //***120123: this block/condition will not need any more since it is only for using openai finetune model creation.
      
      //console.log("in chatbotform.jsx, menuItemSelected is create your chatbot model");
      //const apiUrl2 = ' https://c0o8gnzty3.execute-api.us-west-2.amazonaws.com/prod/fineTuneGPTModel'; 
      const apiUrl3 = 'https://hf4gwgts95.execute-api.us-west-2.amazonaws.com/prod/fineTuneGPTModel'; 
      const data3 = { 
        "email": email,
        "actionFlag": "update"
      };  
      axios.post(apiUrl3, data3, header)
        .then((response) => {
            //console.log("chatbotform.jsx, handleClick() for creating chatbot model, get response:", response);
            alert("Your chatbot model is created successfully."); 
        }).catch((error) => {
            console.log("chatbotform.jsx, handleClick() for creating chatbot model, get error:", error);
        });

    }
    
  }

  componentDidMount() {
    console.log("chatbotform.jsx: componentDidMount!");
   // this.getProfileValue(this.props.token, this.props.email);
  }

  componentDidUpdate() { //after the page loaded/created already and after something changed/updated in the same page/component, e.g. a dropdownlist changed to another item.
    console.log("chatbotform.jsx: componentDidUpdate!");
   // this.getProfileValue(this.props.token, this.props.email);
  } 

  render() { 
        this.state.menuItemSelected = this.props.chatBotMenuItem.toLowerCase();
        //console.log("chatbotform.jsx state is: ", this.state.menuItemSelected);
        return ( 
          
          <div>
            {
             this.props.chatBotMenuItem.toLowerCase().indexOf("video") >-1? (
              <div>
                <h5 style={{ color: 'white'}}>
                    Please upload one 2~8-second talking video or one photo, and click submit button.
                    <br>
                    </br>
                    Note: 
                    <ul>
                      <li>
                        If you upload a video, please ensure:
                      </li>
                      <ul>
                        <li>
                        You talk about 2~8 seconds in the video.
                        </li>
                        <li>
                        You show your face and mouth in the video.
                      </li>
                      </ul>
                      <li>
                        If you upload a photo, please ensure.
                      </li>
                      <ul>
                      <li>
                        The photo shows the face and mouth.
                      </li>
                      </ul>
                    </ul> 
                  </h5>
                    <div class="col-md-12 text-left">
                      <input type="file" accept=".mp4,.jpeg, .jpg" onChange={(e)=>this.handleFileChange(e)} />
                      {this.state.selectedFileName && (
                      <div>
                        <h8 style={{ color: 'white'}}>
                        Selected File: {this.state.selectedFileName}
                        </h8>
                      </div>
                      )}
                      <button  class="btn btn-primary" onClick = {() => this.handleClick(this.props.token, this.props.email, this.props.chatBotMenuItem, "", "")}>Submit</button>
                    </div>
              </div>
             ): (
              this.props.chatBotMenuItem.toLowerCase().indexOf("voice uuids") >-1? (
              <div>
                <h5 style={{ color: 'white'}}>
                    Please click one of the buttons below to setup your default voice.
                    <br>
                    </br>
                    
                    <ul>
                      {
                     /* <li>
                      If you do not know how to create your cloned voice or you just do not want to create your cloned voice,
                      <ul>
                        <li>
                          To set a default female voice, please enter: 792ea935 in the text field for Voice Project UUID, and enter: 0842fdf9 in the text field for Cloned Voice UUID
                        </li>
                        <li>
                          To set a default male voice, please enter: bda0105c in the text field for Voice Project UUID, and enter: 012cafd0 in the text field for Cloned Voice UUID
                        </li>                
                      </ul>    
                      </li>*/ } 

                      <li>
                        To setup your voice with a default female or male voice, please click "Default Female Voice" or "Default Male Voice" button below.
                      </li>
                      <li>
                        If you want to create your own cloned voice, please contact us at support@missu.ai.
                        <br></br>
                        {/*For the next version of Missu.AI, we will enable you to create your own voice through our website directly.*/}
                      </li>
                    </ul>
                       
                  </h5>
                  
                  <div class="row">
                    {/*
                    <div class="form-row">
                      <div class="col-auto">
                          <label class="my-1 mr-2" for="emailToTalk">
                          <h6 className = "text-without-bg"> Your Cloned Voice Project UUID </h6> 
                          </label>
                          <textarea class="form-control" id="voiceProjectUUID" rows="1" cols="10">
                        </textarea>
                      </div>
                      <div class="col-auto">
                        <label class="my-1 mr-2" for="emailToTalk">
                          <h6 className = "text-without-bg"> Your Cloned Voice UUID </h6> 
                          </label>
                        <textarea class="form-control" id="voiceUUID" rows="1" cols="10">
                        </textarea>
                      </div> */}
                    
                      <br></br>
                      <div class="col-md-12 text-left">
                        <button  class="btn btn-primary mr-3" onClick = {() => this.handleClick(this.props.token, this.props.email, this.props.chatBotMenuItem, "792ea935", "0842fdf9")}>Default Female Voice</button>
                      
                        <button  class="btn btn-primary" onClick = {() => this.handleClick(this.props.token, this.props.email, this.props.chatBotMenuItem, "bda0105c", "012cafd0")}>Default Male Voice</button>
                       
                      </div>
                    </div>

                    <h6 style={{ color: 'white'}}>
                      Note: After you click a button, please wait until seeing a message popup to confirm your submission.
                    </h6> 

              </div>
             ):(
              this.props.chatBotMenuItem.toLowerCase().indexOf("clone") >-1? (
                //add this block for allowing a user to upload a zip file containing his 30 recorded voice waf files with transcript csv file
                //as required by resemble API.
                <div>
                <h5 style={{ color: 'white'}}>
                    Please upload your 30 offline recorded voice wav files in the zip , and click submit button.
                  </h5>
                    <div class="col-md-12 text-left">
                      <input type="file" accept=".mp4" onChange={(e)=>this.handleZipFileChange(e)} />
                      {this.state.selectedZipFileName && (
                      <div>
                        <h8 style={{ color: 'white'}}>
                        Selected File: {this.state.selectedZipFileName}
                        </h8>
                      </div>
                      )}
                      <button  class="btn btn-primary" onClick = {() => this.handleClick(this.props.token, this.props.email, this.props.chatBotMenuItem, "", "")}>Submit</button>
                    </div>
              </div>
              ):(
              <div>
                <h5 style={{ color: 'white'}}>
                        Please click submit button to {this.props.chatBotMenuItem}.
                        <br>
                        </br>
                        Note:
                        Before clicking submit button, please ensure that you entered the minimum required information already: 
                        <ul>
                          <li>
                            Basic profile with self introduction.
                          </li>
                          <li>
                            At least one life expeirience.
                          </li>
                          <li>
                            And at least one opinion on a subject.
                          </li>
                          <br></br>
                          Certainly, the more informaiton about you entered, the more comprehensice your "legacy" will be.
                        </ul>  
                </h5>

                <div class="col-md-12 text-center">
                    <button  class="btn btn-primary" onClick = {() => this.handleClick(this.props.token, this.props.email, this.props.chatBotMenuItem, "", "")}>Submit</button>
                </div>
              </div> 
             )
             )
             )
            }
            </div> 
        )
    }
    
} 

export default MyChatBotForm;