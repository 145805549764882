import React, { Component } from 'react';
import axios from 'axios';
import '../App.css';
 
class MyLifeForm extends Component {

    constructor(props) {
        super(props);
      }

    state = {
         menuItemSelected : "",
         file: null,
         selectedFileName: "",
         uploadFile: false,
         imgUrl: ""
      };

      handleFileChange = (e) => {
        //console.log("handleFileChnage() starts...");
        const selectedFile = e.target.files[0];
        if(selectedFile && selectedFile.type.includes('image')) {
            //setFile(selectedFile);
            this.setState({
                file: selectedFile,
                selectedFileName: selectedFile.name,
                uploadFile: true
            })
        } else {
            alert("Error: Not an image file. please only upload an image file.");
        }
        // Clear the file input value to allow re-uploading
        this.fileInputRef.current.value = "";   
      };

  
    handleLifeSave = async (token, email) => {
        
        //console.log(" lifeForm..handleLifeSave() starts....token is:", token);

        //  alert("lifefrom.jsx handleSave start....1, email="+ email);

        const lifeInput = document.getElementById("lifeStageTextarea").value;

        //alert("lifefrom.jsx handleSave start....2, lifeInput="+ lifeInput);

        const wordcount = lifeInput.split(" ").length;

        if (wordcount>1000) {
            alert ("Your input has " + wordcount + " words. Please reduce it to less than 200 words");
        } else {
            
           const apiUrl = 'https://hf4gwgts95.execute-api.us-west-2.amazonaws.com/prod/lifeStage'
           const imageFileExt = (this.state.file === null)? "" : this.state.file.type.split("/")[1]
           const data = { 
                "email": email,
                "lifeStage" : this.state.menuItemSelected,
                "lifeInput" : lifeInput,
                //"eventFlag": "life", //??need it??
                "actionFlag": "save", //"getPresignedURL4UploadingImage",
                "imageFileExtension": imageFileExt
            };
            const header = {
                headers: {
                    'Authorization': `${token}`
                }
            };

        
           // console.log("in lifeForm. handleSave(), header is:", header);
            // alert("lifefrom.jsx handleSave start....3, header="+ header+", data=" + data.lifeStage +", "+data.email+", "+data.lifeInput);
            //if (this.state.imgUrl !== "" && this.state.imgUrl.indexOf("htts") !== -1) {
            //    this.setState({uploadFile:true});
            //}
            if (this.state.uploadFile) {
                let presignedUrl ="";
                axios.post(apiUrl, data, header)
                .then((response) => {
                   // console.log("*****content-type:", this.state.file.type);
                    // console.log("chatbotform.jsx, handleClick() for uploading image, get s3 presigned url response:", response);
                    presignedUrl = response.data;
                    //console.log("presignedURL:", presignedUrl);
                    //alert("get s3 presignedUrl successfully:"); 
                }).then(()=>{
                    // Use the presigned URL to upload the file directly to S3
                   // console.log("*****upload image, content-type:", this.state.file.type);
                    const options = {
                    headers: {
                        'Content-Type': this.state.file.type, // Set the content type of the file
                    },
                    };
                    axios.put(presignedUrl, this.state.file, options);
                }).then(()=>{
                    this.setState({uploadFile: false});
                    alert('Your life stage is saved and image file is uploadeded successfully. Please remember: after you complete entering all your life stages info, you need click Create Life Video in the menu list to create your life video.'); //to S3
                }
                ).catch((error) => {
                    console.log("chatbotform.jsx, handleSave() with upload image file, get error:", error);
                });

            } else { //user did not upload an img file, i.e. 1) only entered life note; OR 2)loaed the existing life note and img file (in such case, refer to getLifeStage() which will have this.stage.uploadFile=false, and this.stage.file=null and hence the fileExt will be "" also.)
                axios.post(apiUrl, data, header).then((response) => {
                // alert("lifefrom.jsx handleSave start response:", response);

                   // console.log("lifeForm.handlesave() get response:", response);
                    alert("Your life stage is saved successfully. Please remember: after you complete entering all your life stage info, you need click Create Life Video in the menu list to create your life video."); 
                }).catch((error) => {
                    console.log("lifeForm.handlesave() get error:", error);
                
                });
            }
        }
  }
  
  
   getLifeStage = async (ptoken, pemail, menuItemSelectedChanged) => {
    // alert("lifefrom.jsx getLifeStage() start....1");
    
    if (this.state.menuItemSelected == "createLifeVideo") {
       // console.log("getLifeStage(), menu selected is createLifeVideo. Do Nothing!");
        return;
    }
         

   // console.log(" getLifeStage start....token is:", ptoken);
       
     const apiUrl2 = 'https://hf4gwgts95.execute-api.us-west-2.amazonaws.com/prod/lifeStage';
   
     const data = { 
         "email": pemail,
         "lifeStage" : this.state.menuItemSelected,
         "lifeInput" : "",
         "actionFlag": "get"
     };
     const header = {
         headers: {
             'Authorization': `${ptoken}`

         }
       };

     
    // console.log("in lifeForm.getLifeStage(), header is:", header);
    // console.log("***in lifeForm.getLifeStage(), request data is:", data);

     if(data.lifeStage != "") {
     
            axios.post(apiUrl2, data, header)
            .then((response)=> {
            // console.log("****response.data is:", response.data);
            // const respData = JSON.parse(JSON.stringify(response.data));
                const respData = response.data;
               // console.log("****1 respData", respData)
                const url = respData.url;
               // console.log("****2 url:", url)
                const lifeNote = respData.lifeNote[0];
               // console.log("****3 lifeNote:", lifeNote);
                if(!menuItemSelectedChanged) {
                    if (lifeNote.trim().length === 0) {
                       // console.log("******In getLifeState(), lifeNote is Nothing!...skip")
                        //document.getElementById("lifeStageTextarea").value = curValue;
                    } else {
                       // console.log("******In getLifeState(), lifeNote is not empty, it is:", lifeNote);
                        document.getElementById("lifeStageTextarea").value = lifeNote; //returnValue; //JSON.stringify(returnValue);
                    }
                } else { //if menuItemSelected Changed.
                    document.getElementById("lifeStageTextarea").value = lifeNote;
                    //this.setState({file: ""});
                     this.setState({file: null});

                    // Update the selected file name for display
                    this.setState({selectedFileName: ""});
                    
                    this.setState({uploadFile: false});
                }
                // console.log("***4 set text area")
                this.setState({imgUrl: url});
            }).catch((error) => {
                console.log("**getLifeStage() get error:", error);
            }); 
        }
   
    }

createLifeVideo = async (ptoken, pemail) => {
    //console.log("  start....token is:", ptoken);
    let returnValue="";
    
    const apiUrl2 = 'https://hf4gwgts95.execute-api.us-west-2.amazonaws.com/prod/lifeStage';

    const data = { 
        "email": pemail,
        "lifeStage" : "",
        "lifeInput" : "",
        "actionFlag": "createLifeVideo"
    };
    const header = {
        headers: {
            'Authorization': `${ptoken}`

        }
    };

    
    //console.log("in lifeForm.createLifeVideo(), header is:", header);
    
    axios.post(apiUrl2, data, header)
    .then((response)=> {
      // console.log("****response.data is:", response.data);
       const retrunValue = response.data.value;
       alert("Your life video creation request has been submitted successfully. Please remember to go to My Chat Bot Menu to submit Create My Model so that your changes in life stages will be built into your Model.");
     
    }).catch((error) => {
        console.log("getLifeStage() get error:", error);
    }); 

    }

    componentDidMount() { // called when this component/page first time created/loaded, called after render().
       // console.log("***lifeform.jsx: componentDidMount!")
        this.getLifeStage(this.props.token, this.props.email);
      }

      
    componentDidUpdate(prevProps, prevState) { //after the page loaded/created already and after something changed/updated in the same page/component, e.g. a dropdownlist changed to another item.
        
      // console.log("***componentDidUpdate()....prevProps, prevState:", prevProps, prevState);

        if (prevProps.lifeStage !== this.state.menuItemSelected ) {
        // console.log("lifeform.jsx: componentDidUpdate() call getLifeState(), menuItemSelecte Changed.");
         //const curValue = document.getElementById("lifeStageTextarea").value
         this.getLifeStage(this.props.token, this.props.email, true);
        } else if (prevState.uploadFile !== this.state.uploadFile ) {
           // console.log("lifeform.jsx: componentDidUpdate() call getLifeState(), uploadFile stateus chagned");
         
            this.getLifeStage(this.props.token, this.props.email, false);
        }
      } 

    render() { 
        this.state.menuItemSelected = this.props.lifeStage;
        //console.log("MyLifeForm state menuItemSelected is: ", this.state.menuItemSelected);
        const selectedMenu=this.state.menuItemSelected;
        return ( 

            <div>
            {
             selectedMenu == "createLifeVideo" ? (
                
              <div>
                <h5 style={{ color: 'white'}}>
                        Please click submit button to create your short life video based on the information you entered in various life stages.
                        <br>
                        </br>
                        Note:
                        Before clicking submit button, please ensure that you entered the minimum required information already: 
                        <ul>
                          <li>
                            Information for at least three life stages.
                          </li>
                          <li>
                            And one picture for each of the three live stages.
                          </li>
                        </ul>  
                </h5>

                <div class="col-md-12 text-center">
                    <button  class="btn btn-primary" onClick = {() => this.createLifeVideo(this.props.token, this.props.email)}>Submit</button>
                </div>
              
              </div>
             ) : (
                <div>
                    <h5 className = "text-without-bg">
                        If not yet, please upload a picture and enter a note about your life - {this.props.lifeStage} in less than 100 words in the text field below, and save them. 
                    </h5>
                    <div>
                        {this.state.imgUrl ? (
                            <img src={this.state.imgUrl} alt="life image" style={{ width: '200px', height: 'auto' }} />
                        ) : (
                            <h7 className = "text-without-bg">
                                <p>Uploade image...</p>
                            </h7>
                        )}
                    </div>

                    <div class="col-md-12 text-left">
                        <input type="file" accept=".jpeg, .jpg" onChange={(e)=>this.handleFileChange(e)} />
                        {this.state.selectedFileName && (
                        <div>
                            <h8 style={{ color: 'white'}}>
                            Selected File: {this.state.selectedFileName}
                            </h8>
                        </div>
                        )}
                    </div>
                    <br></br>
                    <div class="form-group">
                        <label for="exampleFormControlTextarea1"></label>
                        <textarea class="form-control" id="lifeStageTextarea" rows="10" defaultValue=" ">
                            </textarea>
                    </div>

              
                    <div class="col-md-12 text-center">
                        <button  class="btn btn-primary" onClick = {() => this.handleLifeSave(this.props.token, this.props.email)}>Save</button>
                    </div>                
                </div>
                )
            }
            </div>
         );
    }
} 

export default MyLifeForm;