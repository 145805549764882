import React, { Component } from 'react';
import '../App.css';
import axios from 'axios';
 
class PersonToTalkForm extends Component {

    constructor(props) {
        super(props);
       // const apiUrl = 'https://j1nbknreme.execute-api.us-west-2.amazonaws.com/DP-fctest/{myexpproxy+}';  
      };

    state = {
        /* sexValue : "",
         expTopics: "",
         opTopics:  ""*/
         chatbotModelReady: "",
         currentTalkToEmail:"", //FC100223
         checkButtonDisabled: true, //FC100223
         talkButtonDisabled:true, //FC100223
         videoTalkButtonDisabled:true,//FC100223

         //FC added 08/31/23
         s3VideoURL: "pending",
        // showVideo: false, //FC 053024 rm it, no need due to video loading spinner

         //FC 09/03/23
        // showCheckVideo: false, //FC 053024 rm it, no need due to video loading spinner
         showPlayVideo: false,  

         //FC 053024
         videoStatus: "",

         //FC 10/27/24
         lifeVideoUrl: ""
      };

      checkVideoInterval = null; // To store the interval ID

    //FC102724 to get life video presigned url
    getLifeVideo(ptoken, pemail) {
      //console.log(" getLifeVideo() starts....token is:", ptoken);
       
      const apiUrl2 = 'https://hf4gwgts95.execute-api.us-west-2.amazonaws.com/prod/lifeStage';

      const data = { 
          "email":pemail ,
          "lifeStage" : "",
          "lifeInput" : "",
          "actionFlag": "getLifeVideo"
      };
      const header = {
          headers: {
              'Authorization': `${ptoken}`

          }
      };

      
      //console.log("in lifeForm.createLifeVideo(), header is:", header);
      
      axios.post(apiUrl2, data, header)
      .then((response)=> {
        //console.log("****response.data is:", response.data);
        const presignedUrl = response.data;
        if (presignedUrl.indexOf("NoLifeVideo") !== -1   ) {
           // console.log("No Life Video yet")
        } else { 
          //console.log("****presignedUrl:", presignedUrl)
          this.setState({lifeVideoUrl: presignedUrl})
        }
        
      }).catch((error) => {
          console.log("getLifeVideo() get error:", error);
      }); 
    }

    //FC1000223 added the below function
    handleCurrentTalkToEmailChange = (e) => {
     // console.log("****1, this.state.currentTalkToEmail is:", this.state.currentTalkToEmail);
     // console.log("*****2, e.target.value is: ", e.target.value);
      const newCurrentTalkToEmail = e.target.value;
     // console.log("in personToTalkForm's handleCurrentTalkToEmailChange(), newCurrentTalkToEmail is:", newCurrentTalkToEmail);
      if (this.state.currentTalkToEmail == newCurrentTalkToEmail) {
       // console.log("*****3");
        this.setState({checkButtonDisabled: true });
      } else {
        
        this.setState({currentTalkToEmail: newCurrentTalkToEmail });
        this.setState({checkButtonDisabled: false });
        this.setState({talkButtonDisabled: true });
        this.setState({videoTalkButtonDisabled: true });
       // console.log("***********4, the udpated state.currentTalkToEmail is:", this.state.currentTalkToEmail);
        
      }
    };

    //FC added 08/31/23
   /* playVideo = () => {
      this.setState({ showVideo: true });
    };*/ //FC 053024 rm it, no need due to video loading spinner

      //refer to https://reactjs.org/docs/forms.html#controlled-components
    handleCheck = async (ptoken) => {
       // console.log(" Check button clicked, handleCheck() start....");
          //const personEmail = document.getElementById("personToTalkEmailTextarea").value;
         // console.log("personToTalk Email is:", personEmail);
        
         //FC 102924 added the bellow line:
         if (this.state.lifeVideoUrl !== "") {
          this.setState({lifeVideoUrl: ""});
         }
          

        let email = document.getElementById("emailToTalk").value.trim();
       /* let noDataFlag="";
        let noDataFlag2 = "";
        let noDataFlag3 = "";
        let eTopics = "";
        let oTopics = "";*/
    
        const data = { 
            "email": email,
            "actionFlag": "get"
        };
        const header = {
            headers: {
                'Authorization': `${ptoken}`
              // 'Authorization': `${token}` + 'ftest-bad-token-tail'  //this is for testing cognito authorizer, it works (ie. blocked or get error) if we add any extra chars in the true id_token.
            // 'Authorization': `Basic ${token}` 
            }
          }
    
       // console.log("personToTalkForm.handleCheck(), header is:", header);
        
       // const apiUrl1 = 'https://hf4gwgts95.execute-api.us-west-2.amazonaws.com/prod/fineTuneGPTModel'; 
       //**120123: the above one is for using openai finetune model, the below is for using langchain model.
       
       const apiUrl1 = 'https://hf4gwgts95.execute-api.us-west-2.amazonaws.com/prod/langChainModel';
       axios.post(apiUrl1, data, header)
        .then((response) => {
            
            //console.log("chatbotform.jsx, handleClick() for checking chatbot model status, get response:", JSON.stringify(response));
            //somehow, the response schema is diff from the API/Lambda's returned response in fineTuneGPTModelPythonLambda
            //using the console.log to see the response here, find the api's response's body value is in the received reponse's data.
            //the reason is likely that AWS APIG has somewhat Lambda API response transformation. Hence, the resp format is changed.
           // if(JSON.stringify(response.data).indexOf("succeeded")>-1) { // for openai finetunemodel check
            if(JSON.stringify(response.data).indexOf("ready")>-1) { //for langchain model check
              //alert("chatbot model for " + email + " is ready.");  //alert use "+" to combines strings.
              this.state.chatbotModelReady = "Y"; //somehow error IF od:  this.setState({chatbotModelReady: "Y"}); 
              this.props.personToTalkOnClick(this.state.chatbotModelReady, email);
               //FC100223-starts
              this.setState({currentTalkToEmail: email}); 
              this.setState({checkButtonDisabled:true});  
              if (document.getElementById("chatInput")) {
                document.getElementById("chatInput").value="";
              }
              if (document.getElementById("chatbotResp")) {
                document.getElementById("chatbotResp").value="";
              }
              this.setState({talkButtonDisabled: false });
             // this.setState({videoTalkButtonDisabled: false});
             this.setState({videoTalkButtonDisabled: true}); //FC 053024, enable only after talk chat resp back.
              //FC100223-ends

              //FC 053029
              this.setState({showPlayVideo:false});

              //FC 10/29/24
             // console.log("in handleCheck()...calling getLifeVideo()...");
              this.getLifeVideo(ptoken, email);
    
            } else {
              alert("chatbot model for " + email + " is Not ready yet. Please try another Legacy/email");  //alert use "+" to combines strings.
            }
             
            
        }).catch((error) => {
            //alert("personToTalkForm.jsx get error at handleCheck()");
            alert("Sorry, unexpected error. Please ensure the email you entered is correct. The email is case sensitive. Please try it again later.");
           // console.log("personToTalkForm.jsx, handleCheck() for checking chatbot model status, get error:", error);
        });
    };

    handleTalk = async (ptoken,ptalkFromEmail, pemail) => {
     // console.log(" Talk button clicked, handleTalk() starts....");
        //const personEmail = document.getElementById("personToTalkEmailTextarea").value;
       // console.log("personToTalk Email is:", personEmail);
     // let email = document.getElementById("emailToTalk").value;
     let email = pemail; //this is talk to email
  
      const data = { 
          "email": email, //talk to email
          "talkFromEmail": ptalkFromEmail,
          "chatInput": document.getElementById("chatInput").value,
          "actionFlag": "getChatResponse"
      };
      const header = {
          headers: {
              'Authorization': `${ptoken}`
            // 'Authorization': `${token}` + 'ftest-bad-token-tail'  //this is for testing cognito authorizer, it works (ie. blocked or get error) if we add any extra chars in the true id_token.
          // 'Authorization': `Basic ${token}` 
          }
        }
  
     // console.log("personToTalkForm.handleTalk(), header is:", header);
      
      //***this api call is to get query response based on trained finetunemodel through
      // use OpenAI's finetunemodel API to train a customized model, e.g Davinci,
      // based on individual provided data
      //const apiUrl2 = 'https://hf4gwgts95.execute-api.us-west-2.amazonaws.com/prod/fineTuneGPTModel'; 
      
      //using LangChain FW/model replace the above one since it can 
      //handle query by using the data provided by users first, 
      //then, if not able to answer the query, it will call OpenAI chatbot model to answer.
      //and the user data can be only listed as regular txt file, which is convinent to collect and org an user's data
      //not a special prompt format as OpenAI's finetunemodel API required
      
      //const apiUrl2 = 'https://hf4gwgts95.execute-api.us-west-2.amazonaws.com/prod/langChainModel'; 
      
      //****the above api is tring to provide the langchain chatbot funciton via Lambda
      //but it does not work becuase one depended package chromadb, is too big (270MB when including it dependcies)
      //to be setup in lambda with lambda layer, since one lambda total max size is 260MB.
      //hence, use the below one, which setup the API langchain func on an EC2.
      //this is a diff APIG instance from all other APIs since it is the one connect to EC2 app.
      const apiUrl2 = 'https://opb7kaorch.execute-api.us-west-2.amazonaws.com/prod/langchain'
      
      const secdata = { 
        "email": email, //this is talk to email
        "talkFromEmail": ptalkFromEmail,
        "chatInput": document.getElementById("chatInput").value,
        "actionFlag": "getChatResponse",
        "mysec": "02081321"
    };

      axios.post(apiUrl2, secdata, header)
      .then((response) => {
          
          //console.log("chatbotform.jsx, handleClick() for checking chatbot model status, get response:", JSON.stringify(response));
          //somehow, the response schema is diff from the API/Lambda's returned response in fineTuneGPTModelPythonLambda
          //using the console.log to see the response here, find the api's response's body value is in the received reponse's data.
          //the reason is likely that AWS APIG has somewhat Lambda API response transformation. Hence, the resp format is changed.
        
         const chatbotResp = response.data;
         document.getElementById("chatbotResp").value = chatbotResp;

           //FC 090723 to reset this page/component states to the initial state.
           this.setState({ s3VideoURL: "pending" });
          // this.setState({showVideo:false}); //FC 053024 rm it, no need due to video loading spinner
          // this.setState({showCheckVideo:false}); //FC 053024 rm it, no need due to video loading spinner
           this.setState({showPlayVideo:false});  
           this.setState({videoTalkButtonDisabled: false}); //FC053024, enable videoTalk After chat resp back.
          
      }).catch((error) => {
          //alert("personToTalkForm.jsx get error at handleTalk");
          alert("Sorry, unexpected error. Please try it again later.");
         // console.log("personToTalkForm.jsx, handleTalk() for talking w chatbot, get error:", error);
      });
  };

  handleCheckVideo = async (ptoken, ptalkFromEmail, ptalkToEmail, ptalkTimestamp) => {  
   // console.log(" handleCheckVideo() starts....");
    
    //const currentTimestamp = Date.now();
    //FC added 08/31/23 test
   // ptalkTimestamp = "1693424248850";
     

    const data = {
        "actionFlag": "checkChatVideo" ,
        "talkFromUserEmail": ptalkFromEmail,
        "talkToUserEmail": ptalkToEmail,
        "talkType": "video",
        "voiceClipFileName": ptalkFromEmail+"_"+ptalkToEmail+"_"+ptalkTimestamp+".wav",
        "videoResultFileName": ptalkFromEmail+"_"+ptalkToEmail+"_"+ptalkTimestamp+".mp4",
        "botRespText": document.getElementById("chatbotResp").value
    };
    const header = {
        headers: {
            'Authorization': `${ptoken}`
          // 'Authorization': `${token}` + 'ftest-bad-token-tail'  //this is for testing cognito authorizer, it works (ie. blocked or get error) if we add any extra chars in the true id_token.
        // 'Authorization': `Basic ${token}` 
        }
      }

   // console.log("personToTalkForm.handleCheckVideo(), header is:", header);
    
    const apiUrl4 = 'https://hf4gwgts95.execute-api.us-west-2.amazonaws.com/prod/videoChat'; 
    axios.post(apiUrl4, data, header)
    .then((response) => {
        
        //if get response ok, enable the button: check video response.
       //console.log("videoChat API response: ", JSON.stringify(response));
      // if((JSON.parse(response.data)).fileStatus == "1") { //response is a JSON object, not a String. hence, no need do JSON.parse
      if(response.data.fileStatus == "1") {

        //FC 09/03/23
        //this.state.showPlayVideo = true;
       this.setState({ showPlayVideo: true });  

        //TODO: create link to the Chat Video on S3
        //alert("The Chat Video is ready! Please click 'OK' on this alert first before play/watch it.");
       // console.log("handleCheckVideo: video is ready.")
        let vurl = response.data.s3VideoURL;
        this.setState({s3VideoURL:vurl})
        //FC 090523: may should use :  this.setState({ s3VideoURL:response.data.s3VideoURL });
        //this.state.showVideo = true;
       // this.setState({showVideo:true}) //FC 053024 rm it, no need due to video loading spinner

        this.setState({videoStatus:"ready"});

        
       // document.getElementById("s3VideoURLSourceID").src = response.data.s3VideoURL //no need, still works.
       } else {
       // alert("The Chat Video is not ready yet. The System is working on it. Please try it again in about 1-5 minutes. Thak you!");
        console.log("handleCheckVidoe, video is not ready yet.");
        
       }
    }).catch((error) => {
        //alert("personToTalkForm.jsx get error at handleCheckVideo()");
        alert("Sorry, unexpected error. Please try it again later.");
       // console.log("personToTalkForm.jsx, handleCheckVideo(), get error:", error);
    });

  };


  handleVideoTalk = async (ptoken, ptalkFromEmail, ptalkToEmail) => {  
   // console.log(" Video Talk button clicked, handleVideoTalk() starts....");
    
    const talkTimestamp = Date.now();

    const data = {
        "actionFlag": "createChatVideo" ,
        "talkFromUserEmail": ptalkFromEmail,
        "talkToUserEmail": ptalkToEmail,
        "talkType": "video",
        "voiceClipFileName": ptalkFromEmail+"_"+ptalkToEmail+"_"+talkTimestamp+".wav",
        "videoResultFileName": ptalkFromEmail+"_"+ptalkToEmail+"_"+talkTimestamp+".mp4",
        "botRespText": document.getElementById("chatbotResp").value
    };
    const header = {
        headers: {
            'Authorization': `${ptoken}`
          // 'Authorization': `${token}` + 'ftest-bad-token-tail'  //this is for testing cognito authorizer, it works (ie. blocked or get error) if we add any extra chars in the true id_token.
        // 'Authorization': `Basic ${token}` 
        }
      }

   // console.log("personToTalkForm.handleVideoTalk(), header is:", header);

    this.props.videoTalkOnClick(talkTimestamp);
    
    const apiUrl3 = 'https://hf4gwgts95.execute-api.us-west-2.amazonaws.com/prod/videoChat'; 
    axios.post(apiUrl3, data, header)
    .then((response) => {
        
        //if get response ok, enable the button: check video response.
        //Althouhg the BE/API return statusCode in response, somehow, the Web/UI get response.status, not response.statusCode.
       // console.log("videoChat API response: ", response);
      // console.log("videoChat API response.statusCode: ", response.status);
       if(response.status == 200) {
        //document.getElementById("checkVideo").disabled = false; //FC removed it 09/03/23

        //FC 09/02/23
        //this.state.showCheckVideo = true;
       // this.setState({ showCheckVideo: true }); //FC 053024 rm it, no need due to video loading spinner

        //FC 093024 for spinner loading video
        this.setState({videoStatus: "loading" });
        this.setState({showPlayVideo: true})
      }
    }).catch((error) => {
        //alert("personToTalkForm.jsx get error at handleVideoTalk()");
        alert("Sorry, unexpected error. Please try it again later.");
       // console.log("personToTalkForm.jsx, handleVideoTalk(), get error:", error);
        //FC 09/10/23 added the following one line code for hacking...to handle the APIG timeout 504 resp error
        //FC: as the current /vdieoChat lambda does not design well, it cause APIG timeout error (after 30sec)
        //FC: because /videoChat lambda handling createChatVideo, will call resemble.ai's API to get cloned voice
        //FC: in aysnc way, and that code will do looping/sleeping 10 sec until get the cloned voice.
        //FC: hence it will sometimes take more than 30secs to get the response back from videoChat lambda API.
        //FC: when 504 error code received, the Web/FE UI console in Chrome Inspect will show error for CORS, which
        //FC: is not really CORS error (as we can see in inspect/network, CORS/options call is successful with return statusCode 200)
        //FC: solution may use make videoChat to publish to SQS whcih trigger another lambda to call resemble.ai's API for voice cloning.
        //FC: the following hacking code just make the current app work to continue (not block the UI) since the videoChat lambda code
        //FC: works fine and continue to sleep/loop to wait for resemeble.ai's clone voice return, and then
        //FC: publish to a current SQS to invoke the  the BE/lipsync on EC2 to create chatVidoe.mp4 file on S3.
       // this.setState({ showCheckVideo: true }); //FC 053024 rm it, no need due to video loading spinner
    });

    //FC start 05/29/24 for video talk spinner
   /* let loopFlag=true
    while(loopFlag) {
      this.handleCheckVideo(ptoken, ptalkFromEmail, ptalkToEmail, talkTimestamp)
      .then ((resp)=> {
         if(resp) {
          loopFlag = false;
         }
         if(loopFlag) {
          setTimeout(() => {
            alert('waiting for 5sec to check video status again.')
          }, 5000); // Simulate a 3-second delay
        }
      }).catch((err)=>{
        alert("in handleVideoTalk(), handleCheckVideo(), unexpected error. Please try it again later.");
        console.log("personToTalkForm.jsx, handleVideoTalk() calling handleCheckVideo(), get error:", err);
      });
    };*/
   
    this.checkVideoInterval = setInterval(async () => {
      //console.log ("handleVideoTalk()...check video interval starts....");
      await this.handleCheckVideo(ptoken, ptalkFromEmail, ptalkToEmail, talkTimestamp);
      if (this.state.videoStatus == "ready") {
       // console.log("******this.state.videoStatus ...", this.state.videoStatus);
        clearInterval(this.checkVideoInterval); // Clear the interval
        this.checkFileInterval = null; 
       // this.setState({ videoStatus: 'ready'});
      }
    }, 5000); // Check every 5 seconds
    //FC end 05/29/24 for video talk spinner
  };

  

  componentDidMount() {
   // console.log("personToTalkForm.jsx: componentDidMount!");
   // this.getProfileValue(this.props.token, this.props.email);
  }

  componentDidUpdate() { //after the page loaded/created already and after something changed/updated in the same page/component, e.g. a dropdownlist changed to another item.
   // console.log("personToTalkForm.jsx: componentDidUpdate, chatInput value:", document.getElementById("chatInput").value);
   // console.log("personToTalkForm.jsx, state: showCheckVideo, showPlayVideo", this.state.showCheckVideo, this.state.showPlayVideo);
   // console.log("personToTalkForm.jsx, state: s3VideoURL", this.state.s3VideoURL)
    // this.getProfileValue(this.props.token, this.props.email);
   if (document.getElementById("chatInput").value.indexOf(this.props.email)>-1) {
    //console.log("*****chatInput text area has the value of this props.email. reset it to empty.");
    document.getElementById("chatInput").value="";
   // console.log("personToTalkForm.jsx, state: showCheckVideo, showPlayVideo", this.state.showCheckVideo, this.state.showPlayVideo);
   // console.log("personToTalkForm.jsx, state: s3VideoURL", this.state.s3VideoURL)
   }
   
  }

  //FC 053024 for video loading spinner
  // Lifecycle method to clear the interval if the component is unmounted
  componentWillUnmount() {
    if (this.checkVideoInterval) {
      clearInterval(this.checkVideoInterval);
    }
  }

    render() { 
        
       // console.log("personToTalkForm starts. ");

       // const { videoStatus, showCheckVideo, showPlayVideo, s3VideoURL, currentTalkToEmail, checkButtonDisabled,talkButtonDisabled,videoTalkButtonDisabled, } = this.state;
      //FC 053024 rm it, no need due to video loading spinner
        const { videoStatus, s3VideoURL, showPlayVideo, currentTalkToEmail, checkButtonDisabled,talkButtonDisabled,videoTalkButtonDisabled, lifeVideoUrl } = this.state;
     
        //  console.log("showCheckVideo, showPlayVideo", showCheckVideo, showPlayVideo);
      //  console.log("s3VideoURL", s3VideoURL);
        return (  
          <div>
            {
              this.props.chatbotModelReady? (
                <div>
                  {/*FC100223 update starts */}
                  <h5 style={{ color: 'white'}}>
                      You are talking with {currentTalkToEmail? currentTalkToEmail:this.props.email}. 
                      <br></br>
                      If you'd like to talk with another person, please enter that person's email (e.g. abraham_lincoln_test@missu.ai, michael_murphy_test@missu.ai, ed_bowen_test@missu.ai, charlie_munger_test@missu.ai, queen_merneith@missu.ai) and click check button to check if the Legacy is ready for chat.
                    </h5>
                  <div class="form-row">
                    <div class="col-auto">
                        <label class="my-1 mr-2" for="emailToTalk">
                        <h6 className = "text-without-bg"> Email </h6> 
                        </label>
                        <textarea class="form-control" id="emailToTalk" rows="1" cols="100"  onChange = {(e) => this.handleCurrentTalkToEmailChange(e)}>
                      </textarea>
                    </div>
                  </div> 
                    <div class="col-md-12 text-left">
                      <button  class="btn btn-primary" disabled={checkButtonDisabled} onClick = {() => this.handleCheck(this.props.token)}>Check</button>
                    </div>
                    <br></br>
                    {/* Start FC102724: for get life video */ }
                    {/* will get it from handleCheck() which will call this.getLifeVideo() directly.
                     <div class="col-md-12 text-left">
                      <button  class="btn btn-primary" onClick = {() => this.getLifeVideo(this.props.token, this.state.currentTalkToEmail)}>Get Life Video</button>
                    </div>*/}
                    <br></br>
                    <h5 style={{ color: 'white'}}>
                       Life Video {/*of {this.props.email}*/}
                    </h5>
                    <div>
                      {lifeVideoUrl ? (
                        <video width="640" height="360" controls style={{ display: 'block' }}>
                          <source src={this.state.lifeVideoUrl} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <h7 className = "text-without-bg">
                          <p>No Life Video yet...</p>
                        </h7>
                      )}
                    </div>
                     {/*End FC102724: for get life video */ }
                    <br></br>
                  {/*FC100223 update ends */}
                    <h5 style={{ color: 'white'}}>
                      Chatting with the chatbot of {this.props.email}. 
                      Please enter your message in Chat Input text field.
                    </h5>
                    <div class="form-row">
                      <div class="col-auto">
                          <label class="my-1 mr-2" for="chatInput">
                          <h6 className = "text-without-bg"> Chat Input </h6> 
                          </label>
                          <textarea class="form-control" id="chatInput" rows="1" cols="100" placeholder='please enter your message.'>
                          </textarea>
                      </div>
                    </div>

                    <div class="col-md-12 text-left">
                        <button  class="btn btn-primary" disabled={talkButtonDisabled}  onClick = {() => this.handleTalk(this.props.token,this.props.talkFrom, this.props.email)}>Talk</button>
                    </div>

                    <div class="form-row">
                      <div class="col-auto">
                          <label class="my-1 mr-2" for="chatbotResp">
                          <h6 className = "text-without-bg"> Chatbot Response </h6> 
                          </label>
                          <textarea class="form-control" id="chatbotResp" rows="5" cols="100">
                        </textarea>
                      </div>
                    </div>

                    <div class="col-md-12 text-left">
                       <h6 style={{ color: 'white'}}>
                        If you want to watch the video response, please click Video Talk button.
                        </h6>
                        <button  class="btn btn-primary" disabled={videoTalkButtonDisabled}  onClick = {() => this.handleVideoTalk(this.props.token, this.props.talkFrom, this.props.email)}>Video Talk</button>
                        <br></br>
                        <h6 style={{ color: 'white'}}>
                        Note: After click "Video Talk" button, it may take about 1 to 3 minutes to get the video ready. Thank you.
                        </h6>
                    </div>

                    {/* FC 053024, rm the following: FC 09/03/23 add the condition*/}
                    {/*showCheckVideo && (

                    <div class="col-md-12 text-left">
                        <button  class="btn btn-primary" id="checkVideo" onClick = {() => this.handleCheckVideo(this.props.token, this.props.talkFrom, this.props.email, this.props.talkTimestamp)}>Check Video Response</button>
                    </div>
                    )*/}

                    {/*FC 053024 for video loading spinner*/}
                    {showPlayVideo && (

                    <div>
                    {videoStatus === 'loading' && <div className="spinner"></div>}
                    {videoStatus === 'ready' && <div style={{ paddingBottom: '20px' }}>
                      <video controls width="600" height="400" style={{ display: 'block' }}>
                          <source id="s3VideoURLSourceID" src={this.state.s3VideoURL} type="video/mp4" />
                          Your browser does not support the video tag.
                      </video>
                      
                      </div>}
                      </div>
                    )}

                    {/* FC 053024, rm the followings: 09/03/23 add the condition*/}
                    {/*showPlayVideo && (

                    <div>
                      
                      <h5 style={{ color: 'white'}}> Enjoy the chat video:</h5>
                    

                      
                      {this.state.showVideo && this.state.s3VideoURL != "pending" && (
                      <video controls width="600" height="400" style={{ display: 'block' }}>
                          <source id="s3VideoURLSourceID" src={this.state.s3VideoURL} type="video/mp4" />
                          Your browser does not support the video tag.
                      </video>
                      )}
                   
                    </div>
                    )*/}

                </div>
              ) : (
                <div>
                    <h5 style={{ color: 'white'}}>
                      Please enter the email of the person you'd like to talk with, and click check button to check if the Legacy is ready for chat 
                      <br></br>
                      (Note: It may take a little while to see the Talk Panel shown below after clicking Check button. )
                      <br></br> 
                      <br></br>
                      If you do not know any "legacy" ready for you to chat yet,  you may try to chat with 
                      <ul>
                        <li>
                          abraham_lincoln_test@missu.ai
                        </li>
                        <li>
                          michael_murphy_test@missu.ai
                        </li>
                        <li>
                          ed_bowen_test@missu.ai
                        </li>
                        <li>
                          charlie_munger_test@missu.ai
                        </li>
                        <li>
                          queen_merneith@missu.ai
                        </li>
                      </ul>
                      Also, you may chat with your own "legacy" if you have built it.
                    </h5>
                    <div class="form-row">
                    <div class="col-auto">
                        <label class="my-1 mr-2" for="emailToTalk">
                        <h6 className = "text-without-bg"> Email </h6> 
                        </label>
                        <textarea class="form-control" id="emailToTalk" rows="1" cols="100">
                      </textarea>
                    </div>
                    
                </div>

                <div class="col-md-12 text-left">
                    <button  class="btn btn-primary" onClick = {() => this.handleCheck(this.props.token)}>Check</button>
                </div>
                {
                /*<div>
                    <h5 style={{ color: 'white'}}>
                        The topics the soul can talk about:
                        <br></br>
                        In Life Experience: {this.state.expTopics} 
                        <br></br>
                        In Life Opinion: {this.state.opTopics}
                      </h5>
                  </div>  */
                }
                </div>
              )
            } 
       </div>

            
         );
    }
    
    
} 

export default PersonToTalkForm;