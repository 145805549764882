import React, { Component } from 'react';
import axios from 'axios';
import url from 'url';
import '../App.css';

 
class MyProfileForm extends Component {
  
  constructor(props) {
        super(props);
       // const apiUrl = 'https://j1nbknreme.execute-api.us-west-2.amazonaws.com/DP-fctest/{myexpproxy+}';  
      }

    state = {
         menuItemSelected : ""
      };

  

      //refer to https://reactjs.org/docs/forms.html#controlled-components
  handleSave = async (token, email) => {
    
    //console.log("in profileform.jsx, save button clicked, handleSave() start....token is:", token);

    const yobInput = document.getElementById("yearOfBirth").value;

    const selectgpp1 = document.getElementById('sex');
    const selsexvalue = selectgpp1.options[selectgpp1.selectedIndex].value;

    const  fnInput= document.getElementById("firstName").value;
    const lnInput = document.getElementById("lastName").value;
    const siInput = document.getElementById("selfIntro").value;

    

    const wordcount = siInput.split(" ").length;

    if (wordcount>100) {
      alert ("Your input has " + wordcount + " words. Please reduce it to less than 100 words");
    } else {


         //*** the API path naming could be better.
        // I missed resource path when config APIG, like <root>/myexperience/. I forget to creat resource: myexperience in APIG.
        //howvere, as it works, just let it go.
        //const apiUrl = 'https://j1nbknreme.execute-api.us-west-2.amazonaws.com/DP-fctest/{myexpproxy+}'; 
        const apiUrl = ' https://c0o8gnzty3.execute-api.us-west-2.amazonaws.com/prod/profile'; 
        const data = { 
            "email": email,
            "profileType" : this.state.menuItemSelected,
            "yobInput" : yobInput,
            "sexInput": selsexvalue,
            "fnInput": fnInput,
            "lnInput": lnInput,
            "siInput": siInput, //for self introduction
            "eventFlag": "profile",
            "action": "save"
        };
        const header = {
            headers: {
                'Authorization': `${token}`
              // 'Authorization': `${token}` + 'ftest-bad-token-tail'  //this is for testing cognito authorizer, it works (ie. blocked or get error) if we add any extra chars in the true id_token.
              // 'Authorization': `Basic ${token}` 
            }
          };

          
      //  console.log("profileform.jsx, in handleSave(), header is:", header);
        
        axios.post(apiUrl, data, header)
        .then((response) => {
          //  console.log("profileform.jsx, handlesave() get response:", response);
            alert("Your profile is saved successfully"); 
        }).catch((error) => {
            console.log("profileform.jsx, handlesave() get error:", error);
        });
      }
  }
    
  //refer to https://reactjs.org/docs/forms.html#controlled-components
  getProfileValue = async (ptoken, pemail) => {
   // console.log(" profileform.jsx, getProfileValue start....token is:", ptoken);
    let sexValue="";
    let yobValue="";
    let noDataFlag="";
    let firstName="";
    let lastName="";
    let selfIntro="";
      

   // const apiUrl2 = 'https://j1nbknreme.execute-api.us-west-2.amazonaws.com/DP-fctest/{myexpproxy+}';  
    const apiUrl2 = ' https://c0o8gnzty3.execute-api.us-west-2.amazonaws.com/prod/profile';

  
    const data = { 
        "email": pemail,
        "profileType" : this.state.menuItemSelected,
        "eventFlag": "profile",
        "action": "get"
    };
    const header = {
        headers: {
            'Authorization': `${ptoken}`
          // 'Authorization': `${token}` + 'ftest-bad-token-tail'  //this is for testing cognito authorizer, it works (ie. blocked or get error) if we add any extra chars in the true id_token.
        // 'Authorization': `Basic ${token}` 
        }
      }

    
   // console.log("profileform.getProfileValue(), header is:", header);
    
    axios.post(apiUrl2, data, header)
    .then((response) => {
       // console.log("profileform.jsx, getProfileValue()  is :", response);
       // console.log("profileform.jsx, response.data is:", response.data );

        noDataFlag = JSON.parse(JSON.stringify(response.data)).noDataFlag;
       // console.log("profileform.jsx, noDataFlag=", noDataFlag);
        if(noDataFlag == "false") {
           // console.log("profileform.jsx, in noDataFlage is false if condition");
            sexValue = JSON.parse(JSON.stringify(response.data)).sex;
           // console.log("sexValue=", sexValue);
            if(sexValue == "F") {
              document.getElementById("sex").selectedIndex = 1;
            } else if (sexValue == "M") {
              document.getElementById("sex").selectedIndex = 2;
            }

            yobValue = JSON.parse(JSON.stringify(response.data)).yearOfBirth;
            document.getElementById("yearOfBirth").value = yobValue;

            firstName = JSON.parse(JSON.stringify(response.data)).firstName;
            document.getElementById("firstName").value = firstName; 

            lastName = JSON.parse(JSON.stringify(response.data)).lastName;
            document.getElementById("lastName").value = lastName; 

            selfIntro = JSON.parse(JSON.stringify(response.data)).selfIntroduction;
            document.getElementById("selfIntro").value = selfIntro; 
          } else {
           // console.log("opFrom.getLifeOpinion4Vote() noDataFlag is true, response is :", response);
            
          }

      }).catch((error) => {
        console.log("profileform.jsx, getProfileValue() get error:", error);
      }); 
  

  // console.log("getLifeExpValue=", returnValue);
  //  return JSON.stringify(returnValue);
  //  document.getElementById("lifeExperienceTextarea").value = returnValue;

  }

  componentDidMount() {
    this.getProfileValue(this.props.token, this.props.email);
  }

  componentDidUpdate() { //after the page loaded/created already and after something changed/updated in the same page/component, e.g. a dropdownlist changed to another item.
    //console.log("profileform.jsx: componentDidUpdate!");
    this.getProfileValue(this.props.token, this.props.email);
  } 

    render() { 
        this.state.menuItemSelected = this.props.profileType.toLowerCase();
      //  console.log("profileform.jsx state is: ", this.state.menuItemSelected);
        return ( 

            <div>
                <h5 style={{ color: 'white'}}>
                        Please enter your basic profile information and save it. 
                </h5>

                <div class="form-row">
                    <div class="col-auto">
                          <label class="my-1 mr-2" for="firstName">
                          <h6 className = "text-without-bg"> First Name </h6> 
                          </label>
                          <textarea class="form-control" id="firstName" rows="1" cols="30">
                      </textarea>
                    </div>

                    <div class="col-auto">
                          <label class="my-1 mr-2" for="lastName">
                          <h6 className = "text-without-bg"> Last Name </h6> 
                          </label>
                          <textarea class="form-control" id="lastName" rows="1" cols="30">
                      </textarea>
                    </div>
                    
                    <div class="col-auto">
                        <label class="my-1 mr-2" for="sex">
                        <h6 className = "text-without-bg"> Sex </h6> 
                        </label>
                        <select class="custom-select mr-sm-2" id="sex">
                            <option selected>Select F/M...</option>
                            <option value="F">Female</option>
                            <option value="M">Male</option>
                        </select>
                    </div>
                    <div class="col-auto">
                        <label class="my-1 mr-2" for="yearOfBirth">
                        <h6 className = "text-without-bg"> Year of Birth </h6> 
                        </label>
                        <textarea class="form-control" id="yearOfBirth" rows="1" cols="8">
                     </textarea>
                    </div>
                    
                </div>
                <br></br>
                <br></br>

                <div>
                    <h5 style={{ color: 'white'}}>
                            Please enter your self introduction in less than 100 words. 
                    </h5>
                    <div class="form-row">
                      <div class="col-auto">
                          <label for="selfIntro">
                          </label>
                          <textarea class="form-control" id="selfIntro" rows="10" cols="100">
                          </textarea>
                        </div>
                    </div>
                 
                    <div class="col-md-12 text-center">
                        <button  class="btn btn-primary" onClick = {() => this.handleSave(this.props.token, this.props.email)}>Save</button>
                    </div>
                </div>
                
            </div>
         );
    }
} 

export default MyProfileForm;